import type { RouteLocation } from 'vue-router'
import type { MonetateEventType, MonetatePersonalInfo } from '#types/monetate'
import { extractBaseEvents, extractExtraEvents, mapItemLine, reduceActions } from '#core/utils/monetate'

declare module '#app' {
  interface NuxtApp {
    $sendExtraMonetateEvents: (data?: any, event?: MonetateEventType) => void
  }
}
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $sendExtraMonetateEvents: (data?: any, event?: MonetateEventType) => void
  }
}

export default defineNuxtPlugin(() => {
  const route = useRoute()
  const auth = useAuthStore()
  const profileStore = useProfileStore()
  const { sendMonetateEvents } = useMonetate()

  const { monetateConfig } = useFeatureFlags()
  const coordinates = useCookie('Edgescape-Lat-Long').value

  const getBaseEvents = (to: RouteLocation) => {
    if (!monetateConfig?.isMonetateActive) return []
    const { width, height } = useWindowSize()

    const lsCart = useLocalStorage('cart', {} as any)
    const cart = lsCart?.value || {}
    const { totals = {} } = cart

    if (useRuntimeConfig().public.targetEnv === 'PREPROD' && useCookie('stealthGroup').value !== 'trackingGroup')
      useCookie('stealthGroup', { maxAge: 3600 }).value = to.query?.bypassStealthGroup ? 'trackingGroup' : 'stealthGroup'

    const monetatePersonalInfo: MonetatePersonalInfo = {
      host: monetateConfig?.host,
      height: height.value,
      width: width.value,
      coordinates,
      customStates: {
        loggedIn: auth.loggedIn,
        employeeLoggedIn: auth.consumerType === 'EMPLOYEE',
        loyaltyEnrolled: profileStore.profile?.isLoyaltyMember,
        cartEmpty: !cart.totalItems
      },
      interests: profileStore.interests,
      cart: {
        cartItemTotal: totals.itemTotal,
        cartDiscount: totals.totalDiscount,
        items: cart.items?.map(mapItemLine)
      },
      stealth: useCookie('stealthGroup').value
    }
    useLocalStorage('monPersonalInfo', monetatePersonalInfo, { mergeDefaults: true }).value = monetatePersonalInfo
    return extractBaseEvents(to, monetatePersonalInfo)
  }

  const sendExtraMonetateEvents = async (
    eventContent?,
    eventType?: MonetateEventType
  ) => {
    if (import.meta.server || !monetateConfig?.isMonetateActive || !hasCookieConsent(['targeting']))
      return

    const additionalEvents = eventType ? extractExtraEvents(eventContent, eventType, monetateConfig.monetateEnv) : []

    const events = [
      ...getBaseEvents(route),
      ...additionalEvents
    ]

    if (!additionalEvents.length && eventType) return
    const actions = await sendMonetateEvents(events)
    if (actions) window.vfa = reduceActions(actions)
    window.dispatchEvent(new Event('recalculateMonetateVfa'))
  }

  watch(() => route.fullPath, (newVal, oldVal) => {
    if (oldVal && newVal !== oldVal) {
      getBaseEvents(route)
      window.vfaInit(true)
    }
  }, { immediate: true })

  return {
    provide: {
      sendExtraMonetateEvents
    }
  }
})
