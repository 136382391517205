import type { ProductContext } from '#types/gtm'

const baseEvent = {
  eventCategory: 'Enhanced Ecommerce',
  _clear: true,
  nonInteractive: false,
  customMetrics: {},
  customVariables: {}
}

export default {
  onCartLoad: (cart) => {
    return {
      ...baseEvent,
      event: 'cartLoad',
      eventAction: 'Cart Load',
      eventLabel: undefined,
      nonInteractive: true,
      cart: {
        cartId: cart.basketId,
        products: getProductObjectList(cart.items, {
          isCartItem: true,
        })
      },
      _clear: true
    }
  },
  onAddToCart: (cart, products, context?: ProductContext) => {
    const cartItems = cart.items.filter((item) => products.map(({ productId }) => productId).includes(item.productId))

    return {
      ...baseEvent,
      eventLabel: cartItems.map(({ masterId }) => masterId).join('|'),
      ecommerce: {
        currencyCode: cart.currency,
        add: {
          products: getProductObjectList(cartItems, {
            isCartItem: true,
            quantity: products[0].qty,
            omitPosition: true,
            ...context
          })
        }
      },
      event: 'addToCart',
      eventAction: 'Add to Cart',
      customVariables: {}
    }
  },
  onRemoveFromCart: (cart, context?: ProductContext) => ({
    ...baseEvent,
    eventLabel: cart.id,
    ecommerce: {
      currencyCode: cart.currency,
      remove: {
        products: getProductObjectList(cart.items, {
          isCartItem: true,
          ...context
        })
      }
    },
    event: 'removeFromCart',
    eventAction: 'Remove from Cart'
  }),
  onCartUpdate: (cart, context?: ProductContext) => ({
    ...baseEvent,
    event: 'cartUpdate',
    eventAction: 'Cart Update',
    nonInteractive: true,
    cart: {
      cartId: cart.id,
      products: getProductObjectList(cart.items, {
        isCartItem: true,
        omitPosition: true,
        ...context
      })
    }
  })
}
